<template>
	<div class="total">
		<img src="../assets/kh/bg_ding@2x.png" class="bg-ding" />
		<div class="swiper-bar">
			<van-swipe class="swipe" :autoplay="3000" indicator-color="white">
				<van-swipe-item v-for="item in swiper_list" :key="item.Id">
					<van-image @click="clickAdImage(item.ProductId)" width="100%" height="100%" fit="fill"
						:src="item.Url" />
				</van-swipe-item>
			</van-swipe>
			<img v-if="$app == 'dy'" class="dy-code-bar" src="../assets/dy/kh_ewm@2x.png" />
			<!-- code-bar -->
			<img v-else-if="$app == 'kh'" class="dy-code-bar" src="../assets/kh/kh_ewm@2x.png" />
			<img @click="clickShop" src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/dzrk.png"
				class="dy-shop-bar" />
			<!-- v-if="$app == 'dy'" -->
		</div>

		<section v-if="$app == 'dy'">
			<img v-if="org == 1" class="msg-bar" src="../assets/dy/dl_xx@2x.png" />
			<img v-else-if="org == 2" class="msg-bar" src="../assets/dy/jl_xx@2x.png" />
			<img v-else-if="org == 3" class="msg-bar" src="../assets/dy/hrb_xx@2x.png" />
			<img v-else class="msg-bar" src="../assets/dy/xx@2x.png" />
		</section>
		<img v-else-if="$app == 'kh'" class="msg-bar" src="../assets/kh/xx@2x.png" />
		<div class="product-bar">
			<HomeTitleBar :type="Number('1')" title="跟团游" desc="跟  团  热  门" :tab_array="[]"></HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductDestination title="热门目的地" :type="Number('1')" :array="destination_group"
					:img_bg="require('@/assets/kh/gty_rm@2x.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_group" :type="Number('1')" :index="index"
					:key="item.id" :img_src="item.picturePath" :title="item.title" :place="item.cfd"
					:price="item.refPrice + ''" :tag_array="item.tag_array" @clickProductItem="clickProductItem">
				</HomeProductItem>
			</div>
		</div>
		<div class="product-bar">
			<HomeTitleBar v-if="$app == 'kh'" :type="Number('9')" title="聚焦康辉" desc="" :tab_array="[]"></HomeTitleBar>
			<HomeTitleBar v-if="$app == 'dy'" :type="Number('9')" title="聚焦大运" desc="" :tab_array="[]"></HomeTitleBar>
			<div class="product-more-bar">
				<div class="news-bar">
					<img v-if="$app == 'kh'"
						src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/jjkh_banner%402x.png"
						class="news-icon" />
					<img v-else-if="$app == 'dy'"
						src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/jjkh_banner%402x.png"
						class="news-icon" />
					<div class="news-list">
						<div class="news-item" v-for="(item, index) in news_list" :key="item.id"
							@click="clickNews(index)">
							<div v-if="item.typeName == '活动'" class="news-type-orange">
								【活动】
							</div>
							<div v-else class="news-type-blue">【报道】</div>
							<div class="news-title">{{ item.newsTitle }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="product-bar">
			<HomeTitleBar :type="Number('2')" title="东北游" desc="当  季  热  推" :tab_array="['辽宁', '吉林', '黑龙江', '大连']"
				:tab_index.sync="tab_custom_index"></HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductItem v-for="(item, index) in product_custom" :type="Number('2')" :index="index"
					:key="item.id" :img_src="item.picturePath" :title="item.title" :place="item.cfd"
					:price="item.refPrice + ''" :tag_array="item.tag_array" @clickProductItem="clickProductItem">
				</HomeProductItem>
			</div>
		</div>
		<div class="product-bar">
			<HomeTitleBar :type="Number('3')" title="景点门票" desc="爆  款  推   荐" :tab_array="[]"></HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductDestination title="热门主题" :type="Number('3')" :array="destination_ticket"
					:img_bg="require('@/assets/kh/mp_rm@2x.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_ticket" :type="Number('3')" :index="index"
					:key="item.id" :img_src="item.imageUrl" :title="item.title" :place="item.destination"
					:price="item.price + ''" :tag_array="item.tag_array" :isfree="item.isFree"
					@clickProductItem="clickProductItem"></HomeProductItem>
			</div>
		</div>
		<div class="product-bar">
			<HomeTitleBar :type="Number('4')" title="研学实践课" desc="研  学  实   践   课" :tab_array="[]"></HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductDestination title="热门目的地" :type="Number('4')" :array="destination_study"
					:img_bg="require('@/assets/kh/yx_rm@2x.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_study" :type="Number('4')" :index="index"
					:key="item.id" :img_src="item.imageUrl" :title="item.title" place="" :price="item.price + ''"
					:tag_array="item.xqsnTheme" @clickProductItem="clickProductItem">
				</HomeProductItem>
			</div>
		</div>
		<div class="red-bar">
			<img src="../assets/kh/hspx_bg@2x.png" class="red-img" />
			<img src="../assets/kh/ico_hspx2@2x.png" class="red-img2" />
			<div class="product-bar">
				<HomeTitleBar :type="Number('5')" title="红色培训" desc="" :tab_array="[]"></HomeTitleBar>
				<div class="product-more-bar">
					<HomeProductDestination :img_bg="require('@/assets/kh/hs_1@2x.png')"></HomeProductDestination>
					<HomeProductItem v-for="(item, index) in product_red" :type="Number('5')" :index="index"
						:key="item.id" :img_src="item.picturePath" :title="item.title" :place="item.cfd"
						:price="item.refPrice + ''" :tag_array="item.tag_array" @clickProductItem="clickProductItem">
					</HomeProductItem>
				</div>
			</div>
		</div>
		<div class="product-bar">
			<HomeTitleBar :type="Number('6')" title="邮轮游" desc="海  上  城  堡" :tab_array="[]"></HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductDestination title="热门目的地" :type="Number('6')" :array="destination_ship"
					:img_bg="require('@/assets/kh/yly_rm@2x.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_ship" :type="Number('6')" :index="index" :key="item.id"
					:img_src="item.image" :title="item.productName" :place="item.dep" :price="item.price + ''"
					@clickProductItem="clickProductItem"></HomeProductItem>
			</div>
		</div>
		<div class="product-bar">
			<HomeTitleBar :type="Number('7')" title="火车游" desc="最  优  路  线" :tab_array="[]"></HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductDestination title="热门目的地" :type="Number('7')" :array="destination_train"
					:img_bg="require('@/assets/kh/hc_rm@2x.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_train" :type="Number('7')" :index="index"
					:key="item.id" :img_src="item.picturePath" :title="item.title" :place="item.cfd"
					:price="item.refPrice + ''" :tag_array="item.tag_array" @clickProductItem="clickProductItem">
				</HomeProductItem>
			</div>
		</div>
		<img src="../assets/kh/bg_di@2x.png" class="bg-di" />
		<img v-if="show_float_img && $app == 'kh'"
			src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/ts_ewm%402x.png" class="float-img"
			@click="clockFloatImg" />
		<img v-else-if="show_float_img && $app == 'dy'"
			src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/ts_ewm%402x.png" class="float-img"
			@click="clockFloatImg" />
		<img class="ysf" v-if="$app == 'dy'"
			:src="'https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/kefu-dy.png?v='+Math.random()" alt="">
		<img class="ysf" v-if="$app == 'kh'"
			:src="'https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/kefu-kh.png?v='+Math.random()" alt="">
	</div>
</template>

<script>
	import {
		getGroupHotCity,
		getGroupProductList,
		getHomeAdList,
		getCustomProductList,
		getCustomCityProductList,
		getTicketList,
		getThemeProductList,
		getThemeMoreCityList,
		getShipProductList,
		getShipMoreCityList,
		getRedProductList,
		getNewsList,
		getNewTitleProductList,
		getNewTitleMoreCityList,
	} from "@/api/index";
	import HomeProductItem from "../components/Home_Product_Item.vue";
	import HomeProductDestination from "../components/Home_Product_Destination.vue";
	import HomeTitleBar from "../components/Home_Title_Bar.vue";
	export default {
		name: "Home",
		components: {
			HomeProductItem,
			HomeProductDestination,
			HomeTitleBar,
		},
		watch: {
			tab_custom_index(newValue, oldValue) {
				let podId = 0;
				switch (newValue) {
					case 0:
						podId = 3087;
						break;
					case 1:
						podId = 3101;
						break;
					case 2:
						podId = 3110;
						break;
					case 3:
						podId = 3088;
						break;
				}
				let platformid;
				if (this.$app == "dy") platformid = "8";
				else if (this.$app == "kh") platformid = "7";
				getCustomCityProductList(platformid, podId).then((res) => {
					res.objects.forEach((ele) => {
						ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
						if (ele.pd_json != "") {
							ele.cfd = "";
							ele.pd_json.pod.forEach((le, i) => {
								if (i + 1 == ele.pd_json.pod.length) {
									ele.cfd = ele.cfd + le.Pod;
								} else {
									ele.cfd = ele.cfd + le.Pod + "、";
								}
							});
						}
						let tag_array = [];
						ele.strengths.forEach((e) => {
							tag_array.push(e.strength);
						});
						ele.tag_array = tag_array;
					});
					this.product_custom = res.objects;
				});
			},
		},
		data() {
			return {
				show_float_img: true,
				tab_custom_index: -1,
				news_list: [],
				product_group: [],
				product_custom: [],
				product_ticket: [],
				product_study: [],
				product_red: [],
				product_ship: [],
				product_train: [],
				destination_group: [],
				destination_study: [],
				destination_train: [],
				destination_ship: [],
				destination_ticket: [{
						id: 10,
						name: "爆款推荐",
					},
					{
						id: 6,
						name: "冰雪乐园",
					},
					{
						id: 5,
						name: "温泉洗浴",
					},
					{
						id: 4,
						name: "亲子乐园",
					},
					{
						id: 3,
						name: "酒店住宿",
					},
					{
						id: 8,
						name: "自然风光",
					},
					{
						id: 2,
						name: "动植物园",
					},
					{
						id: 9,
						name: "文化古迹",
					},
				],
				swiper_list: [],
				org: Number(this.$route.query.org)
			};
		},
		created() {
			console.log(this.$app);
			this.tab_custom_index = 0;

			this.getHomeAdList();
			this.getGroupProduct();
			this.getCustomProductList();
			this.getTicketList();
			this.getShipProductList();
			this.getShipMoreCityList();
			this.getRedProductList();
			//研学
			this.getThemeProductList(2);
			this.getThemeMoreCityList(2);
			//火车
			// this.getThemeProductList(3);
			// this.getThemeMoreCityList(3);
			this.getNewTitleMoreCityList("30");
			this.getNewTitleProductList("30");

			this.getNewsList();
		},
		methods: {
			clickShop() {
				let org = this.$route.query.org ? this.$route.query.org : ''
				window.open("/ShopList?org=" + org, "_blank");
			},

			clickNews(e) {
				let org = this.$route.query.org ? this.$route.query.org : ''
				window.open("/NewsDetail?id=" + this.news_list[e].id + "&org=" + org, "_blank");
			},

			clockFloatImg() {
				this.show_float_img = false;
			},

			clickAdImage(e) {
				if (e != "") window.open(e, "_blank");
			},

			getNewsList() {
				getNewsList().then((res) => {
					this.news_list = res.objects;
				});
			},

			//获得红色之旅产品列表
			getRedProductList() {
				getRedProductList().then((res) => {
					res.objects.forEach((ele) => {
						ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
						if (ele.pd_json != "") {
							ele.cfd = "";
							ele.pd_json.pod.forEach((le, i) => {
								if (i + 1 == ele.pd_json.pod.length) {
									ele.cfd = ele.cfd + le.Pod;
								} else {
									ele.cfd = ele.cfd + le.Pod + "、";
								}
							});
						}
						let tag_array = [];
						ele.strengths.forEach((e) => {
							tag_array.push(e.strength);
						});
						ele.tag_array = tag_array;
					});
					this.product_red = res.objects;
				});
			},

			//获得游轮更多目的地列表
			getShipMoreCityList() {
				getShipMoreCityList().then((res) => {
					res.data.des.forEach((e) => {
						this.destination_ship.push({
							id: e.key,
							name: e.value,
						});
					});
				});
			},

			//获得游轮产品列表
			getShipProductList() {
				getShipProductList().then((res) => {
					this.product_ship = res.data;
				});
			},

			//获得指定主题目的地列表
			getThemeMoreCityList(id) {
				getThemeMoreCityList(id).then((res) => {
					res.objects.forEach((e) => {
						if (id == 2) {
							//研学
							this.destination_study.push({
								id: e.areaid,
								name: e.areaname,
							});
						} else if (id == 3) {
							//火车
							this.destination_train.push({
								id: e.areaid,
								name: e.areaname,
							});
						}
					});
				});
			},

			//获得指定主题目的地列表
			getNewTitleMoreCityList(id) {
				getNewTitleMoreCityList(id).then((res) => {
					this.destination_train = [];
					res.objects.forEach((e) => {
						this.destination_train.push({
							id: e.areaid,
							name: e.areaname,
						});
					});
				});
			},

			//获得指定主题列表 新主题
			getNewTitleProductList(theme) {
				let platformid;
				if (this.$app == "dy") platformid = "8";
				else if (this.$app == "kh") platformid = "7";
				getNewTitleProductList(platformid, theme, 1, 7, this.search).then(
					(res) => {
						res.objects.forEach((ele) => {
							ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
							if (ele.pd_json != "") {
								ele.cfd = "";
								ele.pd_json.pod.forEach((le, i) => {
									if (i + 1 == ele.pd_json.pod.length) {
										ele.cfd = ele.cfd + le.Pod;
									} else {
										ele.cfd = ele.cfd + le.Pod + "、";
									}
								});
							}
							let tag_array = [];
							ele.strengths.forEach((e) => {
								tag_array.push(e.strength);
							});
							ele.tag_array = tag_array;
						});
						this.product_train = res.objects;
					}
				);
			},

			//获得指定主题产品列表
			// getThemeProductList(id) {
			// 	let platformid;
			// 	if (this.$app == "dy") platformid = "8";
			// 	else if (this.$app == "kh") platformid = "7";
			// 	getThemeProductList(platformid, id).then((res) => {
			// 		res.objects.forEach((ele) => {
			// 			ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
			// 			if (ele.pd_json != "") {
			// 				ele.cfd = "";
			// 				ele.pd_json.pod.forEach((le, i) => {
			// 					if (i + 1 == ele.pd_json.pod.length) {
			// 						ele.cfd = ele.cfd + le.Pod;
			// 					} else {
			// 						ele.cfd = ele.cfd + le.Pod + "、";
			// 					}
			// 				});
			// 			}
			// 			let tag_array = [];
			// 			ele.strengths.forEach((e) => {
			// 				tag_array.push(e.strength);
			// 			});
			// 			ele.tag_array = tag_array;
			// 		});
			// 		if (id == 2) {
			// 			//研学
			// 			this.product_study = res.objects.slice(0, 7);
			// 		} else if (id == 3) {
			// 			//火車
			// 			this.product_train = res.objects.slice(0, 7);
			// 		}
			// 	});
			// },
			getThemeProductList(id) {
				getThemeProductList(
					this.page,
					this.search
				).then((res) => {
					res.result.tag.tagProductList.forEach((ele) => {
						ele.xqsnTheme = ele.xqsnTheme.split(",")
					});
					this.product_study = res.result.tag.tagProductList.slice(0, 7)
				});
			},
			//获得周边游产品列表
			getTicketList() {
				getTicketList().then((res) => {
					this.product_ticket = [];
					res.result.tag.tagProductList.forEach((e) => {
						let tag_array = [];
						tag_array.push(e.themeName);
						e.tag_array = tag_array;
					});
					this.product_ticket = res.result.tag.tagProductList;
				});
			},

			//获得东北定制游产品列表
			getCustomProductList() {
				let platformid;
				if (this.$app == "dy") platformid = "8";
				else if (this.$app == "kh") platformid = "7";
				getCustomProductList(platformid).then((res) => {
					res.objects.forEach((ele) => {
						ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
						if (ele.pd_json != "") {
							ele.cfd = "";
							ele.pd_json.pod.forEach((le, i) => {
								if (i + 1 == ele.pd_json.pod.length) {
									ele.cfd = ele.cfd + le.Pod;
								} else {
									ele.cfd = ele.cfd + le.Pod + "、";
								}
							});
						}
						let tag_array = [];
						ele.strengths.forEach((e) => {
							tag_array.push(e.strength);
						});
						ele.tag_array = tag_array;
					});
					this.product_custom = res.objects;
				});
			},
			//获得首页轮播图
			getHomeAdList() {
				let id;
				if (this.$app == "dy") id = 6704;
				else if (this.$app == "kh") id = 6705;
				getHomeAdList(id).then((res) => {
					this.swiper_list = res.FreeBanners;
				});
			},
			//获得跟团游产品
			getGroupProduct() {
				getGroupHotCity().then((res) => {
					res.GN.slice(1).forEach((e) => {
						this.destination_group.push({
							id: e.Id,
							name: e.Name,
						});
					});
				});
				let platformid;
				if (this.$app == "dy") platformid = "8";
				else if (this.$app == "kh") platformid = "7";
				getGroupProductList(platformid).then((res) => {
					res.objects.forEach((ele) => {
						ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
						if (ele.pd_json != "") {
							ele.cfd = "";
							ele.pd_json.pod.forEach((le, i) => {
								if (i + 1 == ele.pd_json.pod.length) {
									ele.cfd = ele.cfd + le.Pod;
								} else {
									ele.cfd = ele.cfd + le.Pod + "、";
								}
							});
						}
						let tag_array = [];
						ele.strengths.forEach((e) => {
							tag_array.push(e.strength);
						});
						ele.tag_array = tag_array;
					});
					this.product_group = res.objects;
				});
			},

			//点击产品
			clickProductItem(e) {
				// return;
				console.log(e);
				let type = e.type;
				let index = e.index;
				let productType;
				let productId;
				let tab_name;
				switch (type) {
					case 1:
						//跟团游
						productType = 1;
						productId = this.product_group[index].id;
						tab_name = "2-1";
						break;
					case 2:
						//当地参团
						productType = 1;
						productId = this.product_custom[index].id;
						tab_name = "3-1";
						break;
					case 3:
						//周边游
						productType = 2;
						productId = this.product_ticket[index].id;
						tab_name = "4";
						break;
					case 4:
						//研学
						productType = 60;
						productId = this.product_study[index].id;
						tab_name = "5";
						break;
					case 5:
						//红色之旅
						productType = 1;
						productId = this.product_red[index].id;
						tab_name = "8";
						break;
					case 6:
						//游轮
						productType = 3;
						productId = this.product_ship[index].id;
						tab_name = "10-1";
						break;
					case 7:
						//火车
						productType = 1;
						productId = this.product_train[index].id;
						tab_name = "11-1";
						break;
				}
				let org = this.$route.query.org ? this.$route.query.org : ''
				window.open(
					"/Detail?type=" +
					productType +
					"&id=" +
					productId +
					"&tab_name=" +
					tab_name +
					"&org=" + org,
					"_blank"
				);
			},
		},
	};
</script>

<style lang="less" scoped>
	.total {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		width: 1200px;
		margin: 0 auto;
		background-color: #f6f6f6;

		div.news-bar {
			width: 1200px;
			display: flex;
			margin-bottom: 20px;

			img.news-icon {
				width: 846px;
				height: 272px;
			}

			div.news-list {
				display: flex;
				flex-direction: column;
				width: calc(100% - 846px);
				background-color: #fff;
				height: 100%;
				justify-content: space-around;
				padding-top: 9px;
				padding-bottom: 9px;
				box-sizing: border-box;

				div.news-item {
					display: flex;
					padding-left: 5px;
					box-sizing: border-box;
					cursor: pointer;

					div.news-type-orange {
						width: 56px;
						height: 14px;
						font-size: 14px;
						font-weight: 400;
						line-height: 14px;
						color: #fd8e60;
						opacity: 1;
					}

					div.news-type-blue {
						width: 56px;
						height: 14px;
						font-size: 14px;
						font-weight: 400;
						line-height: 14px;
						color: #459eeb;
						opacity: 1;
					}

					div.news-title:hover {
						color: var(--hover_color);
					}

					div.news-title {
						width: calc(100% - 56px - 15px);
						height: 14px;
						font-size: 14px;
						font-weight: 400;
						line-height: 14px;
						color: #666666;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						opacity: 1;
					}
				}
			}
		}

		.float-img {
			width: 100vw;
			height: auto;
			position: fixed;
			bottom: 0px;
			z-index: 2;
			cursor: pointer;
		}

		.bg-ding {
			position: absolute;
			top: 0px;
			width: 100%;
		}

		.bg-di {
			width: 100%;
		}

		.swiper-bar {
			margin-top: 12px;
			width: 1200px;
			height: 410px;
			position: relative;

			.code-bar {
				position: absolute;
				top: 58px;
				right: 30px;
				width: 170px;
				height: 293px;
			}

			.shop-bar {
				position: absolute;
				top: 0px;
				right: 30px;
				width: 170px;
				height: 50px;
			}

			.dy-code-bar {
				position: absolute;
				top: 88px;
				right: 30px;
				width: 170px;
				height: 293px;
			}

			.dy-shop-bar {
				cursor: pointer;
				position: absolute;
				top: 30px;
				right: 30px;
				width: 170px;
				height: 50px;
			}

			.swipe {
				width: 1200px;
				height: 410px;
			}
		}

		.msg-bar {
			width: 1200px;
			height: 56px;
			object-fit: fill;
			margin-top: 12px;
			z-index: 1;
			margin-bottom: 20px;
		}

		.red-bar {
			display: flex;
			position: relative;
			width: 100vw;
			height: 808px;
			justify-content: center;

			.red-img {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 808px;
				z-index: 0;
			}

			.red-img2 {
				position: absolute;
				top: 5px;
				left: 618px;
				width: 222px;
				height: 64px;
				z-index: 0;
			}
		}

		.product-bar {
			display: flex;
			z-index: 1;
			width: 1200px;
			flex-direction: column;

			.product-more-bar {
				display: flex;
				width: 100%;
				flex-wrap: wrap;
			}
		}
	}

	.ysf {
		position: fixed;
		bottom: 30px;
		right: 30px;
		z-index: 9999;
		width: 180px;
	}
</style>
